@import url("../../Globals/colors.css");
.forgot_password {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: var(--backGround-color);
  gap: 30px;
}
.logo_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
}
.logo {
  max-width: 360px;
  max-height: 60px;
}
.title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05000000074505806px;
  text-align: center;
  color: var(--gray-text-color);
}
.container {
  width: 400px;
  Padding:28px 24px 28px 24px;
  background: var(--color-white-container);
  box-shadow: 0px 5px 13px -5px #19171c5a;
  border-radius: 7px;
}
.link {
  text-align: right;
  color: var(--blue-text-color);
  font-weight: 400;
  cursor: pointer;
}
.link_signup{
  text-align: right;
  color: var(--blue-text-color);
  font-weight: 400;
  cursor: pointer;
  margin-left: 20px;
}
.forgot_link{
  width: 100%;
  text-align: right;
  color: var(--blue-text-color);
  font-weight: 400;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .container {
    width: 98%;
  }
  .title{
    font-size: 14px;
    margin: 0;
  }
  .logo{
    max-height: 40px;
    max-width: 80%;
  }

  .link{
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
}
@import url("../../Globals/colors.css");
.login {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: #fff;
  gap: 15px;
  background-image: url("../../Assets/78786.jpg");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
padding-left: 170px;
}
.logo_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 20px; */
  flex-direction: column;
}
.logo {
  max-width: 460px;
  max-height: 80px;
}
.title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05000000074505806px;
  text-align: center;
  color: var(--gray-text-color);
}
.container {
  width: 400px;
  /* height:600px; */
  Padding:28px 24px 28px 24px;
  background: var(--color-white-container);
  box-shadow: 0px 5px 13px -5px #19171c5a;
  border-radius: 10px;
}
.link {
  text-align: right;
  color: var(--gray-text-color);
  font-weight: 500;
}
.link_signup{
  text-align: right;
  color: var(--blue-text-color);
  font-weight: 500;
  cursor: pointer;
}
.forgot_link{
  width: 100%;
  text-align: right;
  color: var(--blue-text-color);
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  margin-top: 19px;
}

@media screen and (max-width: 500px) {
  .container {
    width: 98%;
  }
  .logo{
    max-height: 40px;
    max-width: 80%;
  }
}
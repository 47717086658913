@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
/* body::-webkit-scrollbar {
  width: 100px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

.shadowForDIv {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.css-1rtad1{
--size: 5px!important;
}


.link_css{
  width: 100%;
  text-align: right;
  color: var(--blue-text-color);
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  margin-top: 19px;
}
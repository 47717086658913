.change-password {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  
}
.change-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* width: 50%;   */
  margin-top: 100px;
  
}
.change-password-head {
  width: 100%;
  align-self: self-start;
  font-size: 33px;
  margin-bottom: 30px;
}
.change-password-input {
}
.change-password-buttons {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: end;
  justify-content: end;
  
}
.change-password-button-cancel {
  color: #000;
  border-color: #000;
}
.change-password-button-update {
  background: #000;
  color: #fff;
  width: 200px !important;
}

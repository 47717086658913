.qr-grid {
    width: 1200px;
    /* height: 391px; */
    background-color: #ffff;
    display: grid;
    padding-left: 25px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 268px));
    grid-row-gap: 44.25px;
    grid-column-gap: 30.2px;
    /* justify-content: space-between; */
    overflow: hidden;
    padding-top: 22.12px;
    padding-bottom: 35px;
    justify-content: flex-start;
    background-color: #067eee;
    overflow: visible;
  }
  .qr-grid-bell {
    width: 1410px;
    /* height: 391px; */
    background-color: #ffff;
    display: grid;
    padding-left: 25px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 450px));
    grid-row-gap: 145px;
    grid-column-gap: 8px;
    /* justify-content: space-between; */
    overflow: hidden;
    padding-top: 22.12px;
    padding-bottom: 35px;
    justify-content: flex-start;
    background-color: #067eee;
    overflow: visible;
    padding-top: 72.5px;
  }
  .qr-grid-design_2 {
    width: 1410px;
    /* height: 391px; */
    background-color: #ffff;
    display: grid;
    padding-left: 25px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 339px));
    grid-row-gap: 170px;
    grid-column-gap: 8px;
    /* justify-content: space-between; */
    overflow: hidden;
    padding-top: 22.12px;
    padding-bottom: 35px;
    justify-items: center;
    background-color: #fff;
    overflow: visible;
  }
  .qr-grid-vehicle {
    align-items: center;
    width: 1200px;
    /* height: 391px; */
    background-color: #ffff;
    display: grid;
    grid-column-gap: 25.2px;
  
    padding-left: 37px;
    padding-right: 15px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 268px));
    grid-row-gap: 39.4px;
    /* padding-top: 19.5px; */
    /* justify-content: space-between; */
    overflow: hidden;
    padding-top: 19.5px;
    padding-bottom: 19.5px;
    justify-content: flex-start;
    background-color: #067eee !important;
    overflow: visible;
    padding: 19.6px 15px 19.5px 37px !important;
  }
  @page {
    size: auto;
    margin: 0mm;
    border: none;
  }
  
  @media print {
    .qr-wrap {
      border: none !important;
    }
    .qr-grid-vehicle {
      background-color: #067eee !important;
    }
  }
  
  .qr-wrap {
    width: 258px;
    height: 380px;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    position: relative;
    padding-bottom: 20px;
    background-color: #fff;
  }
  .qr-wrap-vehicle {
    width: 240px;
    height: 300px;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border-radius: 25px; */
    /* border: 14px solid #067eee; */
    position: relative;
    padding-bottom: 10px;
    background-color: #fff;
  }
  
  .borderImg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  
  .dot {
    width: 6px;
    display: flex;
    height: 6px;
    border-radius: 50%;
    background-color: #000;
  }
  
  .qr-val {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
    width: 90%;
    background-color: #edf3fe;
    border-radius: 6px;
    padding: 0 10px;
  
    display: flex;
    align-items: center;
    padding: 6px 10px;
    position: relative;
    z-index: 11;
  }
  
  .qrcode-wrap {
    width: 200px;
    height: 180px;
    padding: 16px;
    position: absolute;
    display: flex;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    background-color: #edf3fe;
    z-index: 10;
    top: 13.5%;
    /* overflow: hidden; */
  }
  .qrcode {
    position: relative;
    z-index: 11;
  }
  .qr-footer {
    display: flex;
    align-items: center;
  }
  
  .qr-footer {
    color: #000;
    font-weight: 600;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .qr-note-vehicle {
    color: #000;
    font-weight: 500;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* margin-top: 1px; */
  }
  .qr-note-vehicle p {
    display: flex;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    /* letter-spacing: 1.6px; */
    text-align: center;
  }
  
  .qr-footer p {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    /* letter-spacing: 1.6px; */
    text-align: center;
  }
  
  .qr-bell-note {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: #067eee;
    margin-top: 8px;
  }
  .qr-footer-img {
    width: 20px;
  }
  .qr-notes {
    padding: 0 15px;
  }
  .qr-notes-bell {
    padding: 0 16px;
    box-sizing: unset;
  }
  
  .qr-notes li {
    font-size: 9px;
    margin: 6px 0;
    font-weight: 500;
    color: #312f2f;
  }
  .qr-notes-bell li {
    font-size: 10px;
    margin: 4px 0;
    font-weight: 600;
    color: #000;
  }
  .footer-logo {
    height: 40px;
    width: 130px;
    /* display: flex; */
    aspect-ratio: auto;
  }
  .footer-logo-bell {
    height: 32px;
    width: 100px;
    /* display: flex; */
    aspect-ratio: auto;
    position: absolute;
    bottom: -35px;
  }
  
  .qr-id {
    position: absolute;
    bottom: 10px;
    right: 17px;
    font-weight: 400;
    color: #7b7b7b;
    font-size: 8px;
  }
  .qr-id-bell {
    position: absolute;
    bottom: 50px;
    right: 42px;
    font-weight: 400;
    color: #7b7b7b;
    font-size: 8px;
    z-index: 11;
  }
  .qr-id-design_2 {
    position: absolute;
      bottom: 0px;
      right: 40px;
      font-weight: 400;
      color: #7b7b7b;
      font-size: 8px;
      z-index: 11;
  }
  .qrbell {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    height: 95%;
    width: 95%;
    transform: translate(-50%,-50%);
  }
  .qrdesign_2_img {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 54.7%;
    height: 550px;
    width: 425px;
    transform: translate(-50%,-50%);
  }
  .qrcode-bell {
    position: relative;
    z-index: 11;
    top: 26px;
  }
  .qr-wrap-bell {
    width: 510px;
    height: 580px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    background-color: #067eee;
  }
  .qr-wrap-desing_2 {
    width: 100%;
    height: 480px;  
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    background-color: #fff;
  }
  .gap {
    grid-column: 1/-1;
    height: 10px;
  }
  
  .btdcqr-footer {
    list-style-type: none;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
  }
  
.toggle_buttons {
  /* width: 500px; */
  padding: 0px 20px;
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 30px;
  gap: 50px;
  max-width: 50%;
}

.logo_container {
  max-width: 100px; /* Set your fixed width */
  height: 50px; /* Set your fixed height */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* background-color: #f0f0f0; Optional: for a background color */
}

.logo_container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure the image fits within the container without distortion */
}
